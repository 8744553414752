import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';
import Close from '../SVG/Close';
import './LoginErrorModal.scss'
import { handleKeyDown } from '../../Utility/ApplicationFormUtility';

export default ({
    handleClose = () => { },
    hideClose = false,
}) => {

    return (
        <div id='login-error-modal-content'>
            <div className="login-error-head_container">
                <div id="login-as__title" className='H5DesktopBlack'>
                Access Denied
                </div>
            </div>
            <div className='content'>
                <p className="CTAGrey">Please contact <a href='mailto:help@mykaleidoscope.com' className='action_body-mailto'>support</a> for further assistance.</p>
            </div>
            <div className='action-footer'>
                <button className='cta-button CTAWhite tertiary cta-button__text' onKeyDown={(e) => handleKeyDown(e, () => handleClose(e))} onClick={handleClose}>Ok</button>
            </div>
        </div>
    )
}