import React, { useState, useEffect } from 'react';
import AppPage from '../../Core/AppPage';
import './login-page.scss';
import Field from '../../Components/Field/Field';
import FlashMessage from '../../Components/FlashMessage/Message';
import OTPInput from '../../Components/OTPReader/OTPReader';
import CTAButton from '../../Components/CTAButton/CTAButton';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import AirPlaneWithShadow from '../../Components/SVG/AirPlaneWithShadow';
import Eyes from '../../Components/SVG/Eyes';
import { MAIN_CONFIG } from '../../../config/main';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Close from '../../Components/SVG/Close';
import ArrowDown from '../../Components/SVG/ArrowDown';
import Loader from '../../Components/Loader/Loader';
import LoginModal from '../../Components/LoginModal/LoginModal';
import { intersection } from 'lodash';
import { checkMarketPlace, handleRedirectHrefUrl, validateEmailRegex } from '../../Utility/HelperFunctions';
import { useRouter } from 'next/router'
import GoogleLogin from 'react-google-login';
import EnterPasswordModal from '../../Components/Application/EnterPasswordModal/EnterPasswordModal';
import SignupModal from '../../Components/SignupModal/SignupModal';
import cookies from 'next-cookies';
import LoginErrorModal from '../../Components/LoginErrorModal/LoginErrorModal';
import AppliedApplicationList from '../../Components/AppliedApplicationList/AppliedApplicationList';
import { API_CONFIG } from '../../../config/api';
import SSoAccountSwitch from '../../Components/SSoAccountSwitch/SSoAccountSwitch';
import CTAAnchorTag from '../../Components/CTAAnchorTag/CTAAnchorTag';
import { checkFieldLength } from '../../Utility/ApplicationFormUtility';


// TODO: Add responsive style to login page
export const Login = ({ setModalState }) => {
    const [OTP, setOTP] = useState("");
    const router = useRouter()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [showManualLogin, setShowManualLogin] = useState(true);
    const [showVerificationPageVisibility, setVerificationPageVisibility] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
    const API = new KaleidoscopeAPI({});
    let adminContext = cookies('context').admincontext
    const userContext = cookies('context').context
    const lastAccessedPath = sessionStorage.getItem('previousPath')
    let redirectApply = sessionStorage.getItem("redirectApply")
    let userEmail = sessionStorage.getItem("userEmail")
    const isRedirectURL = localStorage.getItem("redirectURL")
    let scholarshipId = localStorage.getItem("redirectURL")?.split('/')[2]
    const [ssoAccounts, setSsoAccounts] = useState([])
    useEffect(() => {
        if (userEmail) {
            setEmail(userEmail);
        }
    }, []);

    useEffect(() => {
        if (OTP.length === 6) {
            async function authorizeOTP() {
                const authorizeResponse = await API.authorizeOTP(email, OTP);
                if (authorizeResponse.error) {
                    toast.error(<FlashMessage type={'error'} message={authorizeResponse.error.user_authentication} />)
                } else {
                    // Determine the user role context
                    afterLogin(authorizeResponse)
                }
            }
            authorizeOTP();
        }
    }, [OTP]);

    useEffect(() => {
        if (!userContext) {
            document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'marketPlace=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        }
    }, []);


    const onClosebutton = () => {
        setLoading(true)
        setModalState({
            visible: false,
            class: 'applied-application__List',
            content: ''
        });
        router.push("/", undefined, { shallow: true })
    }

    /**
     * Adds the modal content and shows the modal.
     */

    const showAppliedAppModal = (scholarshipDetail) => {
        localStorage.setItem('secondaryColor', scholarshipDetail.scholarship.secondary_branding_color__c || scholarshipDetail.account.secondary_color__c || MAIN_CONFIG.COLORS.fillGreen)
        setModalState({
            visible: true,
            className: 'applied-application__List',
            content: <AppliedApplicationList
                handleClose={onClosebutton}
                applicationForm={false}
                appliedApplication={scholarshipDetail.applied_applications}
                scholarshipId={scholarshipDetail.scholarship.sfid}
                scholarship={scholarshipDetail.scholarship}
                setLoading={setLoading}
                applicationStepId={scholarshipDetail.application_step.sfid}
                color={scholarshipDetail.scholarship.primary_branding_color__c || scholarshipDetail.account.primary_color__c}
                secondaryColor={scholarshipDetail.scholarship.secondary_branding_color__c || scholarshipDetail.account.secondary_color__c}
            />
        });
    }

    const afterLogin = (auth) => {
        const roles = auth.user.role;
        if (roles.length === 0) {
            accessDenied()
        }else if (roles.length > 1) {
            setLoading(false)
            showModal(roles, auth)
        } else {
            selectRoleLogin(roles[0], auth)
        }

    }

    const onCloseCheck = () => {
        setModalState({
            visible: false,
            id: 'recommender__create',
            class: 'application_step_modal',
            content: ''
        });
    }
    

    const handleLogin = async (e) => {
        setLoading(true)
        e.preventDefault();

        if (showManualLogin) {
            checkEmailExist(e)
        } else {
            // TODO: Handle login with email only
            const otpResponse = await API.createOTP(email);
            if (otpResponse.success) {
                toast.success(<FlashMessage type={'success'} message={otpResponse.message} />);
                setVerificationPageVisibility(true);
                setLoading(false)
            } else {
                toast.error(<FlashMessage type={'error'} message={otpResponse.error ? otpResponse.error : otpResponse.message} />);
                setLoading(false)
            }
        }
    } 

    const checkEmailExist = async (e) => {
        e.preventDefault()
        if (checkFieldLength(email, 80)) {
            toast.error(<FlashMessage type="error" message="Email must be less than 80 characters." />);
            setLoading(false)
        } else if (validateEmailRegex(email)) {
            const response = await API.checkExistingUser(email);
            if (response?.sso_accounts) {
                setLoading(false)
                setShowPasswordField(true)
                setSsoAccounts(response.sso_accounts)
            } else {
                sessionStorage.setItem("userEmail", email)
                router.push(`${MAIN_CONFIG.URLS.SIGNUP}`, undefined, { shallow: true })
                }
        }else{
            toast.error(<FlashMessage message={"Please enter valid email address"} />);
            setLoading(false)
        }
    }

    const handleLoginModal = async (email, password) => {
        const auth = await API.authenticate(email, password, true);
        if (auth.error) {
            // TODO: Handle error here
            toast.error(<FlashMessage type={'error'} message={auth.error.user_authentication} />);
            setLoading(false)
        } else if (redirectApply && isRedirectURL) {
            setLoading(true);
            await handleRedirectApply(auth);
        } else {
            afterLogin(auth);
        }
    }
    
    const handleRedirectApply = async (auth) => {
        document.cookie = `token=${auth.user.token};path=/`;
        document.cookie = `context=APPLICANT;path=/`;
        onCloseCheck();

        const scholarshipDetail = await API.fetchScholarshipById({ token: auth.user.token, scholarshipId: scholarshipId });
        if (scholarshipDetail?.scholarship) {
            if (scholarshipDetail?.scholarship?.allow_multiple_applications__c && scholarshipDetail?.applied_applications && scholarshipDetail?.applied_applications?.length > 0) {
                setLoading(false);
                showAppliedAppModal(scholarshipDetail);
            } else if (scholarshipDetail?.applied_applications && scholarshipDetail?.applied_applications?.length > 0) {
                handleAppliedApplications(scholarshipDetail);
            } else {
                handleApplyNewApplication(scholarshipDetail);
            }
        }
    }
    
    const handleAppliedApplications = (scholarshipDetail) => {
        let URL = `/application/${scholarshipDetail?.scholarship?.sfid || scholarshipDetail?.scholarship?.heroku_id__c}/${scholarshipDetail?.applied_applications[0]?.sfid || scholarshipDetail?.applied_applications[0]?.heroku_id__c}`;
        if (scholarshipDetail.applied_applications[0].status__c === 'Submitted' || scholarshipDetail.applied_applications[0].status__c === 'In Review Board') {
            URL = `/application/${scholarshipDetail?.scholarship?.sfid || scholarshipDetail?.scholarship?.heroku_id__c}/${scholarshipDetail?.applied_applications[0]?.sfid || scholarshipDetail?.applied_applications[0]?.heroku_id__c}?action=completed`;
        }
        router.push(URL, undefined, { shallow: true });
        setLoading(false);
    }
    
    const handleApplyNewApplication = (scholarshipDetail) => {
        router.push(`/apply/${scholarshipDetail?.scholarship?.sfid}/${scholarshipDetail?.application_step?.sfid || ''}`, undefined, { shallow: true });
        setLoading(false);
    }

    /**
     * remove the modal content and hide the modal.
     */
    const hideModal = () => {
        setModalState({
            visible: false,
            className: '',
            content: []
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        if(password.length > 0){
            handleLoginModal(email, password)
        } else {
            setLoading(false)
            toast.error(<FlashMessage type='error' message='Please enter your password' />)
        }
      }
    /**
     * Adds the modal content and shows the modal.
     */
    const showModal = (roles, auth) => {
        let role = []
        role = roles.filter(r => r !== 'Admin')
        setModalState({
            visible: true,
            className: 'login-role-application__List',
            content: <LoginModal handleClose={hideModal} setLoading={setLoading} roles={role} handleLogin={selectRoleLogin} authDetail={auth} />
        });
    }

    const accessDenied = () => {
        setModalState({
            visible: true,
            className: 'login-role-application__List',
            content: <LoginErrorModal handleClose={hideModal} />
        });
    }

    const handleRedirect = (lastAccessedPath, elementsReviewBoard) => {
        if (lastAccessedPath) {
            return lastAccessedPath
        } else {
            return elementsReviewBoard
        }
    }

    const selectRoleLogin = (selectedRole, auth) => {
        setLoading(true)
        let userRoleArg = [];
        let roleContext = ''
        USER_ROLE_CONTEXT.map(role => {
            if (role.role === selectedRole) {
                userRoleArg.push(role);
            }
        })
        roleContext = userRoleArg.length > 0 ? userRoleArg[0] : ''

        document.cookie = `token=${auth.user.token};path=/`;
        document.cookie = `context=${roleContext ? roleContext.context : ''};path=/`;
        //document.cookie = `admintoken=${auth.user.token};path=/`;
        //document.cookie = `admincontext=ADMIN;path=/`;
        delete auth.user.avatar
        // document.cookie = `user=${JSON.stringify(auth.user)};path=/`;
        let lastAccessedUrl = lastAccessedPath && lastAccessedPath?.split("/")?.filter(Boolean);;
        sessionStorage.removeItem('previousPath')

        if (roleContext.context === 'REVIEWER') {
            // window.location.href = handleRedirect(lastAccessedPath, MAIN_CONFIG.ELEMENTS_URLS.REVIEWER.DASHBOARD)
            handleRedirectHrefUrl(handleRedirect(lastAccessedPath, MAIN_CONFIG.ELEMENTS_URLS.REVIEWER.DASHBOARD))
            localStorage.setItem("elementsRBM", true)
            return false
        }

        let url = roleContext.link
        if (lastAccessedPath) {
            url = lastAccessedPath;
        }
        if (roleContext.context === "APPLICANT") {
            if (localStorage.redirectURL) {
                url = localStorage.redirectURL;
            }
            else if (lastAccessedPath){
                url = lastAccessedPath;
            }
             else {
                url = '/applicant/applications';        
            }
        }
        handleRedirectHrefUrl(url)
        // router.push(url, undefined, { shallow: true })


        hideModal()
        // router.push('/', undefined, { shallow: true })
    }

    const forgotPasswordShow = () => {
        setForgotPassword(true)

    }
    const handleSend = async (e) => {
        setLoading(true)
        e.preventDefault();
        // let email = { "email": email }
        const response = await API.sendPasswordLink({ token: "", email: { "email": email } })
        if (response.success) {
            toast.success(<FlashMessage message={response.message} />);
            setLoading(false)
        } else {
            toast.error(<FlashMessage message={response.message} />);
            setLoading(false)
        }

    }

    const handleLoginWithGoogle = async (payload) => {
        const response = await API.googleLogin(payload)
        if (response.success) {
            toast.success(<FlashMessage message={response.message} />);
            setLoading(false)
        } else {
            toast.error(<FlashMessage message={"response is not correct!!"} />);
            setLoading(false)
        }
    }

    const responseGoogle = (response) => {
        console.log("responseGoogle", response);
        handleLoginWithGoogle(response)
        // var res = response.profileObj;
        // signup(response);
    }

    useEffect(() => {
       let pathname = window.location.pathname
        if (pathname === '/account/logout' && adminContext === 'ADMIN') {
          window.location.href = '/admin'
        }
      }, [window.location.pathname]);

    const redirectRoute = (e) => {
        e.preventDefault()
        if (redirectApply && !showPasswordField && !forgotPassword) {
            router.push(`/scholarships/${scholarshipId}`)
            sessionStorage.clear()
            localStorage.clear()
        } else {
            setShowPasswordField(false)
            setForgotPassword(false)
        }
    }

    const samlApiCall = async (accountOBJ) => {
        setLoading(true)
        handleRedirectHrefUrl(`${API_CONFIG.BASE_API_URL}${API_CONFIG.API_VERSION_PATH}/sso/init?idp_uniq_id=${accountOBJ.sfid}`)
    }

    const handleSSoLogin = async (e) => {
        e.preventDefault()
        if(ssoAccounts.length === 1) {
            samlApiCall(ssoAccounts[0])
        } else {
            setModalState({
                visible: true,
                id: 'enter-password-modal__create_SSO',
                class: 'application_step_modal',
                content: <SSoAccountSwitch
                    ssoAccounts={ssoAccounts}
                    samlApiCall={samlApiCall}
                    onCloseCheck={onCloseCheck}
                    setModalState={setModalState}
                />
            });
        }
    }

    const renderPageHeading = () => {
        if (redirectApply && showPasswordField) {
            return 'Welcome back!'
        } else if (redirectApply) {
            return 'Sign-in to save your progress'
        } else {
            return 'Sign In To Kaleidoscope'
        }
    }

    const renderSubHeading = () => {
        if (redirectApply && showPasswordField) {
            return (
                <span className='CTAGrey'>
                    {`It looks like you already have a Kaleidoscope account${redirectApply && '! Enter your password to complete this application.'}`}
                </span>
            )
        } else if (redirectApply) {
            return <span className='CTAGrey'>Before beginning the <b>{redirectApply}</b> application, please enter your email below to sign in or create a Kaleidoscope account.</span>
        } else if (showPasswordField) {
            return (
                <span className='CTAGrey'>{`It looks like you already have a Kaleidoscope account! Enter your password to sign in${ssoAccounts?.length > 0 ? ', Or sign in with sso.' : '.'}`}</span>
            )
        } else {
            return <span className='CTAGrey'>Enter your email address below to sign into your existing Kaleidoscope account, or to create a new account.</span>
        }
    }

    const handleForgotPassword = (e) => {
        e.preventDefault();
        setForgotPassword(true)
    }

    const renderLoginForm = () => {
        if (showVerificationPageVisibility) {
            return (
                <div id='login-code-page'>
                    <h1 id='login-code-page__title' className='H2DesktopGreen'>Check Your Email For A Code</h1>
                    <h1 id='login-code-page__sub-title' className='BodySmallRegularBlack'>We’ve sent a 6-character code to {email}. <br /> The code expires shortly so please enter it soon.</h1>
                    <div
                        style={{
                            padding: 12
                        }}
                    >
                        <OTPInput value={OTP} onChange={setOTP} />
                    </div>

                    <div id='login-code-page__instructions' className='BodySmallRegularGrey'>
                        Can’t find your code? Check your spam folder!
                    </div>
                </div>
            )
        } else if (forgotPassword) {
            return (
                <>
                    <h1 id='login-page__title' className='H2DesktopGreen'>Forgot Your Password?</h1>

                    <form id='login-page__form' onSubmit={handleSend}>
                        <Field id='login-page__email' label='Email Address' placeholder='Email Address' type='email' value={email} handleChange={setEmail} required />
                        <div className='login-page__btn-container'>
                        <CTAAnchorTag className='CTA-Anchor-Tag-css' handleClick={redirectRoute} showArrow={true} ariaLabel="Back button">Back</CTAAnchorTag>
                        <CTAButton id='login-page-reset__cta' type='tertiary'>
                            Recover Password
                        </CTAButton>
                        </div>
                    </form>
                </>
            )
        } else {
            return (
                <>
                    <h1 id='login-page__title' className='H1DesktopGreen'>{renderPageHeading()}</h1>
                    {renderSubHeading()}
                    <form id='login-page__form'>

                        <Field id='login-page__email' label='Email Address' placeholder='Email Address' type='email' value={email} handleChange={setEmail} disabled={showPasswordField} required />
                        {showPasswordField && showManualLogin && 

                            <Field
                                label='Enter Your Password'
                                id={'question_id'}
                                className="application-section__textarea"
                                placeholder='Password'
                                type={'password'}
                                required={true}
                                handleChange={(value) => setPassword(value)}
                            />
                        }
                        {
                            showManualLogin && !redirectApply && !showPasswordField &&
                            <div className="other-role-reset-password">
                                <p className="CTAGrey other-role-reset-password__message">Are you a nominator, endorser, reference, school counselor, third party contributor or review board member? An account has already been created for you! Click <CTAAnchorTag className='password-reset-link' handleClick={handleForgotPassword}>here</CTAAnchorTag> to set your password.</p>
                            </div>
                        }

                        <div className='login-page__btn-container'>
                            {(showPasswordField || redirectApply) && <CTAAnchorTag className='CTA-Anchor-Tag-css' handleClick={redirectRoute} showArrow={true} ariaLabel="Back button">Back</CTAAnchorTag>}
                            {!showPasswordField && <CTAButton id='login-page__cta' type='tertiary' arrow={true} onClick={handleLogin}>
                                Next
                            </CTAButton>}
                            {showPasswordField && <CTAButton id='login-page__cta' type='tertiary' arrow={true} onClick={handleSubmit}>
                                Sign In
                            </CTAButton>}
                        </div>
                        {ssoAccounts?.length > 0 && showPasswordField &&
                            <>
                                <div className="divider-line">
                                    <div className="left-line"></div>
                                    <div className="text CTAGrey">OR</div>
                                    <div className="right-line"></div>
                                </div>
                                <div className="sso-button-login">
                                    <CTAButton
                                        id='task-page__cta'
                                        type='tertiary' onClick={(e) => handleSSoLogin(e)}>
                                        Sign In With SSO
                                    </CTAButton>
                                </div>
                            </>
                        }

                        {
                            (!redirectApply || (redirectApply && showPasswordField)) &&
                            <div className="other-role-reset-password">
                                <p className="CTAGrey other-role-reset-password__message">
                                    Forgot your password? Click <button className="password-reset-link" onClick={handleForgotPassword}>here</button> to reset.</p>
                            </div>
                        }
                    </form>

                    {/* <div id='login-page__instructions' className='BodySmallRegularBlack'>
                    Or you can <a onClick={() => setShowManualLogin(!showManualLogin)} id='login-page__instructions-cta' href='#'>{showManualLogin ? 'sign in via OTP' : 'sign in manually.'}</a>
                </div> */}
                </>
            )
        }
    }

    return (
        <div id='login-page'>
            <Loader loading={loading} />
            {renderLoginForm()}
            <div id='login-page__bottom'>
                <a className='CTAGrey' href='https://www.mykaleidoscope.com/privacy-policy' target="_blank">Privacy and Terms</a>
                <a className='CTAGrey' href='https://www.mykaleidoscope.com/contact_us' target="_blank">Contact Us</a>
            </div>
            <ToastContainer />
        </div>
    );

}

const LoginPage = AppPage(Login);

export default LoginPage;
