import React from 'react';
import '../AccountSwitcher/account-switcher.scss';
import BrandMark from '../SVG/BrandMark';
import { MAIN_CONFIG } from '../../../config/main';
import UserBadge from '../UserBadge/UserBadge';
import DrawnArrow from '../SVG/DrawnArrow';
import Loader from '../Loader/Loader';


export default ({ ssoAccounts, samlApiCall, onCloseCheck, setModalState }) => {


    const handleAccountSwitch = (account) => {
        samlApiCall(account)
        setModalState({
            visible: false
        })
    };

    const renderAccounts = (accounts) => {
        return accounts?.map((account, index) => {
            return (
                <li key={`account-switcher-${index}`}>
                    <a href={'#'} onClick={() => handleAccountSwitch(account)}>
                        <div className='account-switcher__account'>
                            <div className='account-switcher__left'>
                                <UserBadge
                                    className='account-switcher__badge'
                                    firstName={account.name}
                                    // lastName={account.lastName}
                                    profileImage={account.account_logo_url__c}
                                // color={account.secondaryColor}
                                // backgroundColor={account.primaryColor}
                                />
                                <div className='CTABlack account-switcher__company'>{account.name}</div>
                            </div>
                            <DrawnArrow className='account-switcher__arrow' color={MAIN_CONFIG.COLORS.fillBlue} ariaLabel="Right Arrow" />
                            {/* <DrawnArrow className='account-switcher__arrow' color={MAIN_CONFIG.COLORS.fillBlue} ariaLabel="Right Arrow" ariaLabelDescription={`Click here to select ${account.company} account`} /> */}
                        </div>
                    </a>
                </li>
            );
        });
    };

    return (
        <div id='task-modal-content'>
            <div className="enter-password-modal">
                <div className="task-head_container  task-head_container_sso" >
                    <div id='account-switcher__title' className='H1DesktopGreen'>
                        Select Account
                    </div>
                    <div className="event-head H6DesktopGrey cancel-icon" onClick={onCloseCheck}>X</div>
                </div>
                {/* <Loader loading={loading} /> */}
                <div >
                    <ul id='account-switcher__accounts'>
                        {renderAccounts(ssoAccounts)}
                    </ul>
                </div>
            </div>
        </div>
    );
};
